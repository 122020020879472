import * as React from "react"
import { Link } from "gatsby"
import Seo from "../components/utils/seo"
import Layout from "../components/layout/layout"

// markup
const Success = () => {
    return (
        <Layout>
            <Seo title="Success!">
                <meta name="robots" content="noindex" />
            </Seo>
            <title>Contact Form Submitted</title>
            <section className="mt-8 max-w-screen-xl mx-auto">
                <h1>Thanks for getting in touch!</h1>
                <p>
                    <Link to="/">Return to the site</Link>
                </p>
            </section>
        </Layout>
    )
}

export default Success
